import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router'

import PM from './components/PM'
import MessageInput from './components/MessageInput'
import UserInfo from './components/UserInfo'

import { getChat, pm } from './api'

/**
 * 
 * @typedef {object} ChatProps
 * @property {EventSource} connection
 */

/**
 * Chat component
 * @param {ChatProps} props
 */
export default function Chat(props) {
    const { visitor } = window.state
    const [messages, setMessages] = useState([])
    const params = useParams()

    let loadChat = useCallback((uname) => {
        const { hash } = visitor
        if (hash && uname) {
            getChat(uname)
                .then(response => {
                    setMessages(response.data)
                }).catch(console.log)
        }
    }, [visitor])

    let onMessage = useCallback((json) => {
        const msg = JSON.parse(json.data)
        if (msg.user.uname === params.user) {
            setMessages((oldChat) => {
                return [msg, ...oldChat]
            })
        }
    }, [params.user])

    let onSend = async ({ body }) => {
        let result = false
        let res = await pm(params.user, body).catch(console.error)
        result = res.status == 200
        return result
    }
    useEffect(() => {
        document.title = `PM: ${params.user}`
        loadChat(params.user)
    }, [onMessage, loadChat, params.user])
    const uname = params.user
    return (
        <div className="msg-cont">
            <UserInfo uname={uname} />
            {uname ? (
                <div className="chatroom">
                    <ul className="Chat_messages">
                        {
                            messages.map((chat) =>
                                <PM key={new Date(`${chat.timestamp}Z`).getTime()} chat={chat} {...props} />
                            )
                        }
                    </ul>
                    <MessageInput data={{ mid: 0, timestamp: '0', to: { uname: uname } }} onSend={onSend} placeholder='Reply...' />
                </div>
            ) : (
                    <div className="chatroom no-selection"><p>No chat selected</p></div>
                )
            }
        </div>
    )
}
