import { BrowserRouter, Route, Routes } from 'react-router'
import { AppLayout } from './layout'
import { createRoot } from 'react-dom/client'
import { Post } from './post'
import { Settings } from './settings'
import { Login } from './login'
import { Thread } from './thread'
import Contacts from './chats'
import Chat from './chat'
import { Main } from './main'

const App = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path='/login' element={<Login />} />
            <Route path='/post' element={<Post />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/:user/:mid' element={<Thread />} />
            <Route path='/pm' element={<Contacts />} />
            <Route path='/chat' element={<Chat connection={null} />} />
            <Route path='/' element={<Main />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
  
  let root = document.getElementById('app')
  createRoot(root).render(<App />)
  