import { useState, useEffect, useRef, useCallback } from 'react'

import { i18n } from '../i18n'

import Icon from './Icon'
import Button from './Button'

import UploadButton from './UploadButton'


/**
 * StackOverflow-driven development: https://stackoverflow.com/a/10158364/1097384
 * @param {HTMLTextAreaElement & {createTextRange?: Function}} el element
 */
function moveCaretToEnd(el) {
    if (typeof el.selectionStart == 'number') {
        el.selectionStart = el.selectionEnd = el.value.length
    } else if (typeof el.createTextRange != 'undefined') {
        // Internet Explorer
        el.focus()
        var range = el.createTextRange()
        range.collapse(false)
        range.select()
    }
}


/** @external Promise */

/**
 * @typedef {object} MessageInputProps
 * @property {string} text text
 * @property {function({body:string, attach:string | File}):Promise<{
 * newMessage: import('../api').Message,
 * text: string
 * }>} onSend onSend
 * @property {number=} rows rows
 * @property {Array<{tag: string, usageCount: number}>=} tags tags
 * @property {string=} placeholder placeholder
 * @property {function(import('../api').Message):void} onSuccess success callback
 * @property {boolean=} showMedia show media
 */

/**
 * MessageInput
 * @param {import('react').ComponentProps<import('react').FC> & MessageInputProps} props props
 */
export default function MessageInput({ text, rows, tags, placeholder, onSend, onSuccess, showMedia = true }) {

    const [previewURL, setPreviewURL] = useState(null)
    /**
     * @type {import('react').MutableRefObject<HTMLTextAreaElement?>}
     */
    let textareaRef = useRef(null)
    /**
     * @type {import('react').MutableRefObject<HTMLInputElement?>}
     */
    let fileinput = useRef(null)

    let updateFocus = () => {
        const isDesktop = window.matchMedia('(min-width: 62.5rem)')
        if (isDesktop.matches) {
            const textarea = textareaRef.current
            if (textarea) {
                textarea.focus()
                moveCaretToEnd(textarea)
            }
        }
    }
    let updateHeight = () => {
        const el = textareaRef.current
        if (el) {
            const offset = el.offsetHeight - el.clientHeight
            const height = el.scrollHeight + offset
            el.style.height = `${height + offset}px`
        }
    }
    const textChanged = useCallback((event) => {
        setBody(event.target.value)
        updateHeight()
    }, [])
    useEffect(() => {
        textChanged({
            target: {
                value: text
            }
        })
        updateFocus()
    }, [text, textChanged])

    let [body, setBody] = useState(text)

    let handleCtrlEnter = (event) => {
        if (event.ctrlKey && (event.charCode == 10 || event.charCode == 13)) {
            onSubmit({})
        }
    }

    const [tagsExpanded, setTagsExpanded] = useState(false)
    const tagIconName = tagsExpanded ? 'ei-chevron-down' : 'ei-chevron-right'
    let appendTag = (tag) => {
        setBody(prevBody => {
            return `*${tag} ${(prevBody || '')}`
        })
        updateFocus()
    }
    const [attach, setAttach] = useState('')
    let uploadValueChanged = (attach) => {
        setAttach(attach)
        const input = fileinput.current
        if (attach && input && input.files) {
            setPreviewURL(URL.createObjectURL(input.files[0]))
        } else {
            setPreviewURL()
        }
    }
    const [loading, setLoading] = useState(false)
    let onSubmit = (event) => {
        if (event.preventDefault) {
            event.preventDefault()
        }
        const input = fileinput.current
        const file = attach ? input && input.files[0] : ''
        setLoading(true)
        onSend({
            body: body,
            attach: file
        }).then((response) => {
            setLoading(false)
            setPreviewURL()
            if (response && response.newMessage) {
                setAttach('')
                setBody('')
                if (textareaRef.current) {
                    textareaRef.current.style.height = ''
                }
                onSuccess(response.newMessage)
            } else {
                window.alert(`${response && response.text || 'Network error'}`)
                updateFocus()
            }
        }).catch((e) => {
            setLoading(false)
            console.log(e)
        })
    }
    return (
        <>
            <form className="msg-comment-target" style={{ padding: '12px', width: '100%' }} onSubmit={onSubmit}>
                <div style={commentStyle}>
                    <textarea onChange={textChanged} onKeyPress={handleCtrlEnter}
                        ref={textareaRef} style={textInputStyle}
                        rows={rows || 1} placeholder={placeholder} value={body || ''} />
                    <div style={inputBarStyle}>
                        {showMedia && <UploadButton inputRef={fileinput} value={attach} onChange={uploadValueChanged} />}
                        {
                            loading ? <Icon name='ei-spinner' size='s' /> :
                                <Button onClick={onSubmit}><Icon name="ei-envelope" size="s" />{i18n('postForm.submit')}</Button>
                        }
                    </div>
                </div>
                {previewURL && <img alt="Attachment preview" src={previewURL} style={{ maxWidth: '100%', height: 'auto' }} />}
            </form>
            {
                tags && tags.length > 0 &&
                <div style={{ padding: '12px' }}>
                    <button className="Button" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                        onClick={() => {
                            setTagsExpanded(prev => { return !prev })
                        }}>
                        <Icon name={tagIconName} size="s" /><span className='noselect'>{i18n('postForm.tags')}</span>
                    </button>
                    {
                        tagsExpanded && <div style={{ padding: '6px', display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                            {
                                tags.map(t => {
                                    return (<Button key={t.tag} onClick={() => { appendTag(t.tag) }}>{t.tag}</Button>)
                                })
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
}

/**
 * @type {import('react').CSSProperties}
 */
const commentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10px'
}

/**
 * @type {import('react').CSSProperties}
 */
const inputBarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px'
}

/**
 * @type {import('react').CSSProperties}
 */
const textInputStyle = {
    overflow: 'hidden',
    resize: 'none',
    display: 'block',
    boxSizing: 'border-box',
    border: 0,
    outline: 'none',
    padding: '4px'
}
