import { createElement, memo } from 'react'

const spritesUrl = new URL('evil-icons/assets/sprite.svg', import.meta.url)

/**
 * @typedef {object} IconProps
 * @property {string} size
 * @property {string=} className
 * @property {string} name
 * @property {boolean=} noFill
 */

 /**
  * Icon inner component
  * @param {IconProps} props - icon props
  */
function IconElement(props) {
    var size = props.size ? ' icon--' + props.size : ''
    var className = props.className ? ' ' + props.className : ''
    var klass = 'icon' + (!props.noFill ? ' icon--' + props.name : '') + size + className

    var name = '#' + props.name + '-icon'
    var useTag = `<use xlink:href='${spritesUrl}${name}' />`
    var Icon = createElement('svg', { className: 'icon__cnt', dangerouslySetInnerHTML: { __html: useTag } })
    return createElement(
        'div',
        { className: klass },
        wrapSpinner(Icon, klass)
    )
}

/**
 * @param {import('react').ReactElement} Html
 * @param {string} klass
 */
function wrapSpinner(Html, klass) {
    if (klass.indexOf('spinner') > -1) {
        return createElement(
            'div',
            { className: 'icon__spinner' },
            Html
        )
    } else {
        return Html
    }
}

export default memo(IconElement)
