import { useEffect, useState } from 'react'

import { getChats } from './api'


import Contact from './components/Contact.js'
import { ChatSpinner } from './components/Spinner'

/**
 *
 */
export default function Contacts() {
    const [pms, setPms] = useState()
    useEffect(() => {
        document.title = 'Messages'
        getChats()
            .then(response => {
                setPms(response.data.pms)
            }).catch(console.log)
    }, [])
    return (
        <div className="msg-cont">
            <div style={chatListStyle}>
                {
                    pms ? pms.length > 0 ? pms.map((chat) =>
                        <Contact key={chat.uname} user={chat} style={chatTitleStyle} />
                    )
                        : <div>No chats</div>
                        : <><ChatSpinner /><ChatSpinner /><ChatSpinner /><ChatSpinner /><ChatSpinner /></>
                }
            </div>
        </div>
    )
}

/**
 * @type {import('react').CSSProperties}
 */
const chatListStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '12px'
}

/**
 * @type {import('react').CSSProperties}
 */
const chatTitleStyle = {
    width: '100%',
    padding: '12px',
    textAlign: 'left',
    background: 'var(--main-background-color)',
    color: 'var(--text-color)',
    borderBottom: '1px solid var(--border-color)'
}
