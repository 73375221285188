import { memo } from 'react'

/**
 * @param {import('react').ClassAttributes<HTMLButtonElement> & import('react').ButtonHTMLAttributes<HTMLButtonElement>} props
 */
function Button(props) {
    return (
        <button className="Button" {...props} />
    )
}

export default memo(Button)
