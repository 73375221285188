import { useEffect, useRef } from 'react'
import { i18n } from './i18n'
import { useSearchParams } from 'react-router'
import Icon from './components/Icon'

export const Login = () => {
  const [params] = useSearchParams()
  const signInRef = useRef()
  const nickRef = useRef()
  const { authErrorMessage } = window.state
  const showLoginForm = () => {
    signInRef.current.style.display = 'block'
    nickRef.current.focus()
  }
  useEffect(() => {
    if (params.get('error')) {
      showLoginForm()
    }
  }, [params])
  return (
    <>
      <div id="signup">
        { i18n('label.register') }:
        <div id="facebook">
          <a href="/_fblogin" rel="nofollow"><Icon name='ei-sc-facebook' size='s' />Facebook</a></div>
        <div id="vk">
          <a href="/_vklogin" rel="nofollow"><Icon name='ei-sc-vk' size='s' />VK ID</a></div>
        <div id="apple">
          <a href="/_apple" rel="nofollow">&nbsp;Apple</a>
        </div>
      </div>
      <div id="signin">
        <a href="#signin" onClick={(e) => {
          e.preventDefault()
          showLoginForm()
          return false
        }}>
          { i18n('question.areRegistered') }
        </a>
        <div ref={signInRef} id="signinform">
          <form action="/login" method="POST">
            <p className="err">{ authErrorMessage }</p>
            <input className="txt" type="text" name="username" placeholder={ i18n('label.username') }
              id="nickinput" ref={nickRef} autoComplete="username" />
            <input className="txt" type="password" name="password"
              placeholder={ i18n('label.password') } autoComplete="current-password" />
            <input className="submit" type="submit" value="OK" />
          </form>
        </div>
      </div>
    </>
  )
}
