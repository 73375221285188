import { Link, Outlet, useLocation } from 'react-router'

import { i18n } from './i18n'

import { NavBar } from './navbar'
import { Sidebar } from './sidebar'

import Icon from './components/Icon'

const SettingsTabs = () => {
  return (
    <div id="pagetabs">
      <div className="toolbar">
        <Link to="/settings?page=main">{i18n('link.settings.main')}</Link>
        <Link to="/settings?page=password">{i18n('link.settings.password')}</Link>
        <Link to="/settings?page=about">{i18n('link.settings.about')}</Link>
        <a href="/logout"><Icon name="ei-user" size="s" />{i18n('link.logout')}</a>
      </div>
    </div>
  )
}

const HomeTabs = () => {
  const { tags } = window.state
  return (
    <>
      <div className="toolbar">
        <a href="/?show=top" title="Top">
          <Icon name="ei-heart" size="s" />
          <span className="desktop">Top</span>
        </a>
        <a href="/?show=all" title={i18n('link.allMessages')}>
          <Icon name="ei-search" size="s" />
          <span className="desktop">{i18n('link.allMessages')}</span>
        </a>
        <a href="/?show=photos" title={i18n('link.withPhotos')}>
          <Icon name="ei-camera" size="s" />
          <span className="desktop">{i18n('link.withPhotos')}</span>
        </a>
      </div >
      <div className="tags desktop">
        <h4>{i18n('link.trends')}</h4>
        {
          tags && tags.map(tag => <a key={tag} href={`/tag/${tag}`} title={tag}>{tag}</a>)
        }
      </div>
    </>
  )
}

export const AppLayout = () => {
  const links = decodeLinks(window.links)
  const location = useLocation()
  return (
    <>
      <NavBar />
      <div id="content_wrapper">
        <aside id="column">
          <div id="sidebar_wrapper">
            {
              location.pathname === '/settings' ?
                <SettingsTabs /> : location.pathname === '/login' ? <HomeTabs /> : <Sidebar key={location.pathname + location.search} />
            }
            <div id="footer" className="desktop">
              <div id="footer-left">juick.com &copy; 2008-2025
                {
                  links &&
                  <div dangerouslySetInnerHTML={{__html: `<br/>${i18n('label.sponsors')}: ${links}`}} />
                }
              </div>
              <div id="footer-right">
              <span>&nbsp;&middot;&nbsp;</span><a href="/help/contacts" rel="nofollow">{i18n('link.contacts')}</a>
              <span>&nbsp;&middot;&nbsp;</span><a href="/help/tos" rel="nofollow">{i18n('link.tos')}</a>
              <span>&nbsp;&middot;&nbsp;</span><a href="/help/privacy" rel="nofollow">{i18n('link.privacy')}</a>
              </div>
            </div>
          </div>
        </aside>
        <section id="content" className="content--top">
          <Outlet />
        </section>
      </div>
    </>
  )
}

const strip = (s) => {
  var div = document.createElement('div')
  div.innerHTML = s
  var scripts = div.getElementsByTagName('script')
  var i = scripts.length
  while (i--) {
  scripts[i].parentNode.removeChild(scripts[i])
  }
  return div.innerHTML
}

const decodeLinks = (/** @type { string } */ data) => {
  const links = data && JSON.parse(decodeURIComponent(escape(atob(data))))
  return links && links.pageLinks && links.pageLinks.map(strip).join(links.linkDelimiter)
}
