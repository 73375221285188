import { memo } from 'react'

import Avatar from './Avatar'

/**
 * @typedef {object} ContactProps
 * @property {import('../api').Chat} user
 * @property {import('react').CSSProperties} style
 */

/**
 * Contact component
 * @param {ContactProps} props
 */
function Contact({ user, style }) {
    return (
        <Avatar user={user} link={`/chat?user=${user.uname}`} style={style}>
            {user.unreadCount && <span className="badge">{user.unreadCount}</span>}
            <div className="msg-ts">{user.lastMessageText}</div>
        </Avatar>
    )
}

export default memo(Contact)
