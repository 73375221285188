import { Link, NavLink, useSearchParams } from 'react-router'
import { i18n } from './i18n'
import Icon from './components/Icon'

export const NavBar = () => {
  const { visitor, retpath } = window.state
  const [params] = useSearchParams()
  return (
    <div id="header">
      <div id="header_wrapper">
        {
          visitor.uid > 0 ? (
            <div id="user_wrapper">
              <a href={`/${visitor.uname}/`}>
                <img src={visitor.avatar} alt="" />{visitor.uname}
                {
                  visitor.premium &&
                  <span style={{ color: 'green'}}><Icon name="ei-star" size="s" /></span>
                }
                {!visitor.verified &&
                  <span style={{ color: 'red'}}><Icon name="ei-exclamation" size="s" /></span>
                }
              </a>
            </div>
          ) : (
            <div id="logo"><a href="/">Juick</a></div>
          )
        }
        <div id="search" className="desktop">
          <form action="/">
            <input name="search" className="text"
              placeholder={ i18n('label.search') } defaultValue={ params.get('search') || '' } />
          </form>
        </div>
        <nav id="global">
          <Link to="/">
            <Icon name="ei-bell" size="s" />
            <span className="icon-title desktop">{ i18n('link.discuss') }</span>{ visitor.unreadCount > 0 && <span className="badge">{ visitor.unreadCount }</span> }
          </Link>
          <Link to="/?show=all" rel="nofollow">
            <Icon name="ei-search" size="s" />
            <span className="icon-title desktop">{ i18n('link.allMessages') }</span>
          </Link>
          {
            visitor.uid > 0 ? (
              <NavLink id="post" to="/post">
                <Icon name="ei-pencil" size="s" />
                <span className="icon-title desktop">{ i18n('link.postMessage') }</span>
              </NavLink>
            ) : (
              <NavLink className="a-login" to={`/login?retpath=${ retpath || '/'}`} rel="nofollow">
                <Icon name="ei-user" size="s" />
                <span className="icon-title desktop">{ i18n('link.Login') }</span>
              </NavLink>
            )
          }
        </nav>
      </div>
    </div>
  )
}
