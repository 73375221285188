import Icon from './Icon'

/**
 * @typedef {object} UploadButtonProps
 * @property {string} value
 * @property {import('react').MutableRefObject<HTMLInputElement>} inputRef
 * @property {Function} onChange
 */

/**
 * Upload button
 * @param {UploadButtonProps} props
 */
export default function UploadButton(props) {
    /** @type { import('react').MouseEventHandler<HTMLButtonElement> } */
    let openfile = (e) => {
        e.preventDefault()
        const input = props.inputRef.current
        if (props.value) {
            props.onChange('')
        } else {
            input.click()
        }
    }

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    let attachmentChanged = (event) => {
        props.onChange(event.target.value)
    }
    return (
        <button className='Button' style={props.value ? activeStyle : inactiveStyle}
            onClick={openfile}>
            <Icon name="ei-camera" size="s" />
            <input type="file" accept="image/jpeg,image/png" onClick={e => e.stopPropagation()}
                style={{ display: 'none' }} ref={props.inputRef} value={props.value}
                onChange={attachmentChanged} />
        </button>
    )
}

const inactiveStyle = {
    color: '#888'
}
const activeStyle = {
    color: 'green'
}
