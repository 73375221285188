import { memo, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router'

import { info, fetchUserUri } from '../api'

import Avatar from './Avatar'
import Icon from './Icon'
import defaultAvatar from '../../assets/av-96.png'

let isMounted

/**
 * User info component
 * @param {{uname: string, onUpdate?: Function, children?: import('react').ReactElement}} props
 */
export default function UserInfo({ uname, onUpdate, children }) {
    const [user, setUser] = useState({
        uname: uname,
        uid: 0
    })
    useEffect(() => {
        isMounted = true
        info(uname).then(response => {
            if (isMounted) {
                onUpdate && onUpdate(response.data)
                setUser(response.data)
            }
        }).catch(console.log)
        return () => {
            isMounted = false
        }
    }, [onUpdate, uname])
    return (
        <>
            <div className="userinfo">
                <Avatar user={user}>
                    <div className="msg-ts">Was online recently</div>
                </Avatar>
            </div>
            <UserSummary user={user} />
            <div className="l">
                {
                    user.uid > 0 &&
                    <>
                        <Link to={`/pm/${user.uname}`}>
                            <Icon name="ei-envelope" size="s" />
                            <span className="desktop">PM</span>
                        </Link>
                        <Link to={`/${user.uname}/?show=recomm`} rel="nofollow">
                            <Icon name="ei-heart" size="s" />
                            <span className="desktop">Recommendations</span>
                        </Link>
                        <Link to={`/${user.uname}/?media=1`} rel="nofollow">
                            <Icon name="ei-camera" size="s" />
                            <span className="desktop">Photos</span>
                        </Link>
                    </>
                }
            </div>
            {children}
        </>
    )
}

/**
 * User summary component
 * @param {{user: import('../api').User}} props
 */
function Summary({ user }) {
    const readUrl = `/${user.uname}/friends`
    const readersUrl = `/${user.uname}/readers`
    const blUrl = `/${user.uname}/bl`
    let read = user.read && <Link key={readUrl} to={readUrl}>I read: {user.read.length}</Link>
    let readers = user.readers && <Link key={readersUrl} to={readersUrl}>My readers: {user.readers.length}</Link>
    let mybl = user.statsMyBL && <Link key={blUrl} to={blUrl}>My blacklist: {user.statsMyBL}</Link>
    let presentItems = [read, readers, mybl].filter(Boolean)
    return (
        <div className="msg-summary">
            {presentItems.length > 0 && presentItems.reduce((prev, curr) =>
                [prev, ' ', curr])}
        </div>
    )
}

const UserSummary = memo(Summary)


/**
 * Link to user
 * @param {{ user: import('../api').User}} props
 */
export function UserLink(props) {
    const [user, setUser] = useState(props.user)
    const userRef = useRef(user)
    const sizeClass = props.size === 'small' ? 'info-avatar' : 'msg-avatar'
    useEffect(() => {
        isMounted = true
        if (userRef.current.uri) {
            fetchUserUri(userRef.current.uri).then(remote_user => {
                if (isMounted) {
                    setUser(remote_user)
                }
            }).catch(() => {
                setUser({
                    uid: 0,
                    uname: userRef.current.uri,
                    uri: userRef.current.uri,
                    avatar: defaultAvatar
                })
            })
        }
        return () => {
            isMounted = false
        }
    }, [])
    return (
        user.uid ?
            <>
                <Link to={`/${user.uname}/`} reloadDocument={true} className={sizeClass}>
                    <img src={user.avatar || defaultAvatar} alt={user.uname} />
                </Link>
                <Link to={`/${user.uname}/`} reloadDocument={true} >
                    {user.uname}
                    {user.premium && (
                        <span style={{ color: 'green' }}>
                            <Icon name="ei-star" size="s" />
                        </span>
                    )}
                </Link>
            </>
            : (
                <>
                    <a href={user.url || user.uri} className={sizeClass}>
                        <img src={user.avatar || defaultAvatar} alt={user.uname} />
                    </a>
                    <a href={user.url || user.uri}>{user.uname}</a>
                </>
            )
    )
}
