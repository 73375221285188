import { memo } from 'react'

import Avatar from './Avatar'
import { format } from '../utils/embed'
import { chatItemStyle } from '../helpers/BubbleStyle'

function PM(props) {
    const { chat } = props
    const { visitor } = window.state
    return (
        <li>
            <div style={chatItemStyle(visitor, chat)}>
                <Avatar user={chat.user} />
                <div className={visitor && visitor.uid === chat.user.uid ? 'msg-bubble msg-bubble-my' : 'msg-bubble'}>
                    <p dangerouslySetInnerHTML={{ __html: format(chat.body, '0', false) }} />
                </div>
            </div>
        </li>
    )
}

export default memo(PM)
