export const registerServiceWorker = () => {
    const publicKey = 'BPU0LniKKR0QiaUvILPd9AystmSOU8rWDZobxKm7IJN5HYxOSQdktRdc74TZvyRS9_kyUz7LDN6gUAmAVOmObAU'
    navigator.serviceWorker.register(new URL('../sw.js', import.meta.url), { scope: '/' })
    navigator.serviceWorker.ready.then(reg => {
        return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: publicKey
        })
    }).then(
        sub => {
            return [
                {
                    type: 'web',
                    token: JSON.stringify(sub)
                }
            ]
        },
        err => console.error(err)
    ).then(body => {
        return fetch('/api/notifications', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
    }).then(response => {
        console.log(response.status)
    }).catch(console.error)
}
