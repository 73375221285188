import { lazy } from 'react'

const MessageInput  = lazy(() => import('./components/MessageInput'))
import { post, update } from './api'
import { useLocation, useNavigate } from 'react-router'
import { i18n } from './i18n'

export const Post = () => {
    const { twitter1, visitor, tags } = window.state
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const location = useLocation()
    let editingMessage = location.state && location.state.data || {}
    const isEditing = !!editingMessage.mid
    let postMessage = async ({ attach, body }) => {
        try {
            const data = (body || '').trim()
            const res = isEditing ? await update(editingMessage.mid, editingMessage.rid || 0, data) : await post(data, attach)
            let result = res.status == 200
            if (result) {
                return res.data
            }
            return result
        } catch (e) {
            alert(e.response.data.text || 'Error')
            console.log(e)
        }
        return false
    }

    let navigateToThread = (msg) => {
        navigate(`/${visitor.uname}/${msg.mid}`)
    }
    return (
        <>
            <p className="page">{ isEditing ? i18n('postForm.edit') : i18n('postForm.newMessage') }</p>
            <article className='page-content'>
                {
                    twitter1 &&
                    <h2 style={{
                        color: 'white',
                        background: '#ff339a',
                        padding: '20px'
                    }}>Reconnect or disable your Twitter account</h2>
                }
                <MessageInput rows={7} text={params.get('body') || editingMessage.body}
                    tags={isEditing ? [] : tags} onSend={postMessage}
                    onSuccess={navigateToThread} placeholder='*weather It is very cold today!'
                    showMedia={!isEditing} />
            </article>
        </>
    )
}
