/**
 * @param {import('../api').User} me
 * @param {import('../api').Message} msg
 * @returns { import('react').CSSProperties} CSS properties
 */
export function chatItemStyle(me, msg) {
    const user = msg.user
    const isMe = me && me.uid === user.uid
    const alignment = isMe ? 'flex-end' : 'flex-start'
    return {
        padding: '3px 6px',
        listStyle: 'none',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: alignment
    }
}
